import { Link } from 'gatsby'
import React from 'react'

const Footer = props => (
  <footer id="footer">
    <ul className="icons">
      <li>
        <a href="mailto:info@stakenode.one"
          className="icon fa-envelope alt"
        >
          <span className="label">info@stakenode.one</span>
        </a>
      </li>
      <li>
        <a
          href="https://github.com/stakenode"
          className="icon fa-github alt"
        >
          <span className="label">GitHub</span>
        </a>
      </li>
    </ul>
    <p className="copyright">
      &copy; {new Date().getFullYear()} stakenode.one.
    </p>
  </footer>
)

export default Footer
